.gallery-card:hover
.delete-button
{
    visibility: visible;
}

.delete-button{
    visibility: hidden;
}

